.item-grid {
  margin: auto;
}

.item-grid-tile {
  background: #fff;
  border: 1px solid #999;
  float: left;
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  height: 34px;
  width: 34px;
  margin-right: -1px;
  margin-top: -1px;
  margin-bottom: -1px;
  text-align: center;
}

.item-grid-row {
    clear: both;
    content: "";
    display: table;
}
