.resource-spawn {
  display: flex;
  flex-direction: column;
  border: 1px solid #999;
  border-radius: 20px;
  height: 210px;
  width: 130px;
  margin: 20px;
}

.resource-spawn-name {
  text-align: center;
  margin-bottom: -1px;
}
